import styled from 'styled-components';
import React from 'react';

const StyledAboutCard = {};

StyledAboutCard.Container = ({ children }) => <Container>{children}</Container>;
const Container = styled.div``;

StyledAboutCard.Title = styled.h2``;

StyledAboutCard.Text = styled.p`
  font-size: 1.1em;
  margin: 0 0 2em 1em;
  position: relative;
`;

export default StyledAboutCard;

import {
  StyledBody,
  StyledSection,
  StyledArticle,
  Row,
} from "styles/Containers";
import React from "react";
import { StyledH1 } from "styles/Typography";
import SEO from "components/seo";
import { SharedLayout } from "layouts/SharedLayout";
import StyledAboutCard from "styles/AboutCard";

class About extends React.Component {
  render() {
    return (
      <SharedLayout>
        <StyledH1> About Us </StyledH1>
        <SEO title="About" />
        <StyledSection>
          <StyledArticle>
            <StyledAboutCard.Container>
              <StyledAboutCard.Title>Who are we?</StyledAboutCard.Title>
              <StyledAboutCard.Text>
                Real Safe Cars was created by Ben Shiller and Bhoomija Ranjan in
                2019 after they realized that existing public car safety
                information presented an incomplete picture of car safety. The
                ranking process was inspired by their joint paper with Siqi Liu,
                'Optimal Presentation of Quality Ratings: Application to
                Coarsened Automobile Crashworthiness Ratings'. Shiller and
                Ranjan are both economists at Brandeis University who specialize
                in structural modeling.
              </StyledAboutCard.Text>
            </StyledAboutCard.Container>
            <StyledAboutCard.Container>
              <StyledAboutCard.Title>Why rank cars?</StyledAboutCard.Title>
              <StyledAboutCard.Text>
                One founder went through a crisis. A family member was very
                sick, and a friend died in a car accident. Through this
                difficult period, he developed a deep desire to save lives, but
                it was not immediately obvious how he could --- he was not a
                doctor or chemist, but rather a professor of
                economics/statistics. In the process searching for a new car –
                he had his eureka moment. He could develop better safety
                rankings. Existing car safety ratings were opaque, and moreover
                it was difficult to distinguish between the many cars rated as
                “safe.” He believed he could do so much better. In truth, it
                might have always been his destiny --- his family has long had a
                passion for car safety, always choosing the safest-rated
                white-colored cars, which are easiest for others to see (well,
                except in a blizzard). At family gatherings, there are a comical
                number of white cars.
                <br />
                <br />
                He sought out the best colleagues for the project. His two
                like-minded cofounders were equally enthralled and enthusiastic
                about the idea. Together, we developed this idea from concept to
                reality.
              </StyledAboutCard.Text>
            </StyledAboutCard.Container>
            <StyledAboutCard.Container>
              <StyledAboutCard.Title>What do we do?</StyledAboutCard.Title>
              <StyledAboutCard.Text>
                RealSafeCars presents car safety ratings that were developed by
                a process of computer modeling. Our models incorporate a range
                of different data sources. These ratings are presented in a
                meaningful fashion so that the magnitude of the differences
                between different models are clear.
              </StyledAboutCard.Text>
            </StyledAboutCard.Container>
            <StyledAboutCard.Container>
              <StyledAboutCard.Title>
                Why do we believe our ratings are better?
              </StyledAboutCard.Title>
              <StyledAboutCard.Text>
                Car safety ratings organizations do necessary work and saved
                countless lives. Our ratings are presented as full alternatives
                to those ratings. Rather, we believe that those ratings should
                be used as a starting point. By combining those ratings with
                accident statistics and using computer modeling to assess what
                car safety tests really show us, we believe our ratings offer
                some big advantages.
                <ul>
                  <li>
                    Others’ ratings are based on very particular testing
                    conditions. Others’ ratings will, for example, provide good
                    information about how well someone will fare if impacted
                    from the side by a 3000 pound vehicle traveling at 30 miles
                    per hour. But what happens if impacted at 50 miles per hour,
                    or by a heavier vehicle? Their ratings are not well-designed
                    to answer this question. We designed our ratings to better
                    evaluate crashworthiness under real-world crash scenarios.
                  </li>
                  <li>
                    Others’ ratings often award the top rating to many cars. But
                    some cars with the same discrete rating are much safer than
                    others. Only our ratings provide information about which
                    cars are really the safest.{" "}
                  </li>
                  <li>
                    Others’ ratings are not comparable across different model
                    years. Our ratings are.
                  </li>
                </ul>
              </StyledAboutCard.Text>
            </StyledAboutCard.Container>
            <StyledAboutCard.Container>
              <StyledAboutCard.Title>
                {" "}
                Why are causality analyses important?{" "}
              </StyledAboutCard.Title>
              <StyledAboutCard.Text>
                We strive to predict which cars protect occupants best. But
                proper analysis requires great care. Raw correlations between
                features and fatality rates may lie. Consider minivans. Is their
                low fatality rate attributable to safety features, or habits of
                their drivers? Maybe minivan drivers transporting their children
                drive unusually cautiously, explaining the low fatality rate.
                Our readers, however, want to know how well a car will protect
                them, not the other way around. By combining artificial
                intelligence methods --- which do not account for causality ---
                with advanced causality analyses, we are able to answer the
                question our readers truly care about – how well does a car
                protect its occupants?
              </StyledAboutCard.Text>
            </StyledAboutCard.Container>
            <StyledAboutCard.Container>
              <StyledAboutCard.Title>
                How do I find the model year of a vehicle?
              </StyledAboutCard.Title>
              <StyledAboutCard.Text>
                All vehicles sold in the US must have a unique 17-character
                Vehicle Identification Number (VIN). The VIN is shown on the
                lower portion of the windshield on the driver’s side, just above
                the hood/windshield wiper. It is easily visible when looking
                through the windshield from outside the vehicle. The VIN should
                also be shown on the manufacturer’s label affixed to the door
                jam on the driver’s side.
                <br />
                <br />
                The 10th character of the VIN denotes the model year, via the
                following coding.
                <br />
                <br />
                <Row spaceAround>
                  <table>
                    <tr>
                      <td>
                        <strong>VIN’s 10th character&nbsp;&nbsp;</strong>
                      </td>
                      <td>
                        <strong>Model year</strong>
                      </td>
                    </tr>
                    {[
                      ["A", 2010],
                      ["B", 2011],
                      ["C", 2012],
                      ["D", 2013],
                      ["E", 2014],
                      ["F", 2015],
                      ["G", 2016],
                      ["H", 2017],
                      ["J", 2018],
                      ["K", 2019],
                      ["L", 2020],
                      ["M", 2021],
                    ].map((a) => (
                      <tr>
                        <td>{a[0]}</td>
                        <td>{a[1]}</td>
                      </tr>
                    ))}
                  </table>
                </Row>
              </StyledAboutCard.Text>
            </StyledAboutCard.Container>
          </StyledArticle>
        </StyledSection>
      </SharedLayout>
    );
  }
}

export default About;
